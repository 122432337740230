import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { isEmpty } from 'underscore';

import { Breadcrumb, Menu, Footer } from '../components';

const { Content } = Layout;

const AppLayout = ({ children, breadcrumb, basic }) => (
    <Layout className="layout">
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="React CMS" />
            <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>

        {!basic ? (
            <>
                <Menu />

                <Content>
                    {!isEmpty(breadcrumb) ? <Breadcrumb breadcrumb={breadcrumb} /> : null}

                    <div className="layout-content">
                        {children}
                    </div>

                </Content>

                <Footer />
            </>
        ) : children}
    </Layout>
);

AppLayout.defaultProps = {
    children: null,
    breadcrumb: null,
    basic: false,
};

AppLayout.propTypes = {
    children: PropTypes.node,
    breadcrumb: PropTypes.array,
    basic: PropTypes.bool,
};

export default AppLayout;
