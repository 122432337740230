import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import spermLine from '../assets/img/spermLineShort.svg';

const { Footer } = Layout;

const AppFooter = () => (
    <Footer>
        <div className="footer-links">
            <div className="site-links">
                <ul>
                    <li>RESEARCH</li>
                    <li>Overview</li>
                    <li>Publications</li>
                    <li>Talks</li>
                    <li>Software</li>
                    <li>Gallery</li>
                </ul>

                <ul>
                    <li>TEACHING</li>
                    <li>Overview</li>
                    <li>Workshops</li>
                    <li>Videos</li>
                </ul>

                <ul>
                    <li>PHOTOGRAPHY</li>
                    <li>Overview</li>
                    <li>Collections</li>
                </ul>
            </div>

            <div className="footer-break">
                <img src={spermLine} alt="footer break" />
            </div>

            <ul className="social-links">
                <li>Gitlab</li>
                <li>Twitter</li>
                <li>Instagram</li>
                <li>Facebook</li>
            </ul>
        </div>

        <div className="footer-copy">
            <div>@copyright</div>
            <div>Designed by Mesh</div>
        </div>
    </Footer>
);

AppFooter.defaultProps = {
    children: null,
};

AppFooter.propTypes = {
    children: PropTypes.node,
};

export default AppFooter;
