import axios from 'axios';
import { isObject } from 'underscore';

const { API_URL } = require('../constants').default;

// ----- Helpers ----- //

// Get Api Url
const getApiUrl = () => `${API_URL}/api/`;

// Get hostname url
const getBaseUrl = () => API_URL;

// defaults for axios
const api = axios.create({
    baseURL: getApiUrl(),
});

// Format params for api call
const formatParams = (payload, key) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }

    return params;
};

// ----- Api Functions ----- //

function fetchApi(options) {
    const data = options;
    data.headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...options.headers,
    };

    return api(data);
}

export {
    getApiUrl,
    getBaseUrl,
    formatParams,

    fetchApi,
};
