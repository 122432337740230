import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';

import spermLine from '../assets/img/spermLineFull.svg';

const { Header } = Layout;
const { Item } = Menu;

const AppMenu = () => (
    <Header>
        <div className="logo">
            DR MEURIG T. GALLAGHER
        </div>

        <Menu mode="horizontal">
            <Item key="1">RESEARCH</Item>
            <Item key="2">TEACHING</Item>
            <Item key="3">PHOTOGRAPHY</Item>
        </Menu>

        <img className="header-border" src={spermLine} alt="header border" />
    </Header>
);

AppMenu.defaultProps = {
    children: null,
};

AppMenu.propTypes = {
    children: PropTypes.node,
};

export default AppMenu;
