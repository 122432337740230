const {
    REACT_APP_ENV,
    REACT_APP_URL,
    REACT_APP_API_URL,
    REACT_APP_COMING_SOON,
} = process.env;

export default {
    ENV: REACT_APP_ENV,
    APP_NAME: 'React Boilerplate',

    APP_URL: REACT_APP_URL,
    API_URL: REACT_APP_API_URL,

    REACT_APP_COMING_SOON: REACT_APP_COMING_SOON,
};
