import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title, children, img, small }) => (
    <div className={`title ${small ? 'title-small' : ''}`}>
        <div className="title-content">
            <h2>{title}</h2>
            <div className="title-description">
                {children}
            </div>
        </div>
        {img ? (
            <div className="title-img">
                <img src={img} alt={title} />
            </div>
        ) : null}
    </div>
);

Title.defaultProps = {
    children: null,
    img: null,
    small: false,
};

Title.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    img: PropTypes.string,
    small: PropTypes.bool,
};

export default Title;
