import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_NAVIGATION_REQUEST: null,
    GET_NAVIGATION_SUCCESS: null,
    GET_NAVIGATION_FAILURE: null,
});

export const {
    getNavigationRequest,
    getNavigationSuccess,
    getNavigationFailure,
} = createActions(
    constants.GET_NAVIGATION_REQUEST,
    constants.GET_NAVIGATION_SUCCESS,
    constants.GET_NAVIGATION_FAILURE,
);
