import React from 'react';

import Layout from '../core/layout';

import meurig from '../assets/img/meurig.png';
import sperm from '../assets/img/spermLineShort.svg';

const ComingSoon = () => (
    <Layout basic>
        <div className="coming-soon">
            <div className="text-container">
                <h1>{process.env.REACT_APP_TITLE}</h1>
                <img src={sperm} alt="divider" />
                <div>
                    Research<div className="divider" />Teaching<div className="divider" />Photography
                </div>
                <div>Coming Soon</div>
            </div>
            <div className="img-container">
                <img src={meurig} alt={process.env.REACT_APP_TITLE} />
            </div>
        </div>
    </Layout>
);

export default ComingSoon;
