import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { Link } from '@reach/router';

const AppBreadcrumb = ({ breadcrumb }) => {
    const itemRender = (route, params, routes, paths) => {
        return routes.indexOf(route) === routes.length - 1 ? (
            <span>{route.breadcrumbName}</span>
        ) : (
            <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
        );
    };

    return <Breadcrumb routes={breadcrumb} itemRender={itemRender} />;
};

AppBreadcrumb.defaultProps = {
    breadcrumb: [],
};

AppBreadcrumb.propTypes = {
    breadcrumb: PropTypes.array,
};

export default AppBreadcrumb;
