import React from 'react';
import { Router } from '@reach/router';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import ComingSoon from '../pages/ComingSoon';

const CoreRouter = () => (process.env.REACT_APP_COMING_SOON ? (
    <Router>
        <ComingSoon path="*" />
    </Router>
) : (
    <Router>
        <Home path="/" />
        <NotFound path="*" />
    </Router>
));

export default CoreRouter;
