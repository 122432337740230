import { Record } from 'immutable';

const {
    GET_NAVIGATION_REQUEST,
    GET_NAVIGATION_SUCCESS,
    GET_NAVIGATION_FAILURE,
} = require('../actions/navigation').constants;

const InitialState = Record({
    navigation: {},
    error: null,
    isFetching: false,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function navigationReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_NAVIGATION_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_NAVIGATION_SUCCESS:
        return state.set('isFetching', false).set('navigation', payload);

    case GET_NAVIGATION_FAILURE:
        return state.set('isFetching', false).set('error', payload);

    default:
        return state;

    }
}
