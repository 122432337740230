import React from 'react';

import Layout from '../core/layout';
import { Title } from '../components';

import meurig from '../assets/img/meurig.png';

const Home = () => (
    <Layout breadcrumb={[{ breadcrumbName: 'Home', path: '/' }, { breadcrumbName: 'App', path: '/app' }]}>
        <Title title="Dr Meurig T. Gallagher" img={meurig}>
            I am currently a Research Fellow in the School of Mathematics, University of Birmingham.
            My research concerns modelling behaviours of biological flows,
            and the creation and development of algorithms for high-throughput automated processing of experimental images.
        </Title>
    </Layout>
);

export default Home;
