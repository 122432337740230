import { call, put, takeEvery, all } from 'redux-saga/effects';
import { fetchApi } from '../utils/api';

import {
    getNavigationSuccess,
    getNavigationFailure,
} from '../actions/navigation';

const {
    GET_NAVIGATION_REQUEST,
} = require('../actions/navigation').constants;

function* getNavigation() {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/navigation',
        });

        yield put(getNavigationSuccess(response));
    } catch (e) {
        yield put(getNavigationFailure(e.response));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(GET_NAVIGATION_REQUEST, getNavigation),
    ]);
}
