/* global document: true */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './core/store';
import CoreRouter from './core/router';

import './assets/styles/styles.css';

const store = configureStore();

ReactDOM.render((
    <Provider store={store}>
        <CoreRouter />
    </Provider>
), document.getElementById('root'));
