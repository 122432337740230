import React from 'react';

import Layout from '../core/layout';

const NotFound = () => (
    <Layout>
        <h1>NotFound</h1>
    </Layout>
);

export default NotFound;
